import React from 'react';
import { ConfigService } from './services';
import UserStorage from './services/UserStorage';
import ClientFactory from './ClientFactory';
import axiosInstance from './plugins/axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_VINA, USER_ODA, AUTH_USERS } from './constants';

export const AppContext = React.createContext();

export class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      config: null,
      project: null,
      authUser: JSON.parse(localStorage.getItem(AUTH_USERS)) || [],
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get('u');
    this.user_vina_id =
      this.state.authUser[
        userId && !(Number(userId) > Number(this.state.authUser.length - 1)) ? Number(userId) : 0
      ]?.id || '';
  }

  get user() {
    return this.state.user;
  }

  get config() {
    return this.state.config;
  }

  get project() {
    return this.state.project;
  }

  async loadConfig() {
    const config = await ConfigService.getConfig();
    this.setState({ config });
    return config;
  }

  async handleSignIn(accessToken, refreshToken, odaToken, isLoginFromApp, userId, openFromApp) {
    if (isLoginFromApp !== 'true') {
      return await this.getDataMyself(accessToken, refreshToken);
    } else {
      const { isExistAccessToken, isExistRefreshToken, isExistOdaToken } = this.getToken();
      if (
        isExistOdaToken === odaToken &&
        isExistAccessToken === accessToken &&
        isExistRefreshToken === refreshToken
      ) {
        if (openFromApp !== 'true') {
          window.open(
            `vinacad://ac=${accessToken}&rf=${refreshToken}&ot=${odaToken}&u=${userId}`,
            '_self'
          );
        }
        window.location.replace(
          `${window.location.pathname}${userId.length ? `?u=${userId}` : ''}`
        );
      } else {
        return await this.getDataMyself(accessToken, refreshToken);
      }
    }
  }

  async getDataMyself(accessToken, refreshToken) {
    const res = await axiosInstance.get('/users/myself', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (res.data) {
      let authUserArr = Object.assign(this.state.authUser);
      const userId = res.data.id;
      const mailUser = res.data.email;
      const fullName = res.data.fullName;
      if (!this.state.authUser.some((item) => item.id === userId)) {
        authUserArr.push({
          id: userId,
          email: mailUser,
          fullName: fullName,
        });
        localStorage.setItem(AUTH_USERS, JSON.stringify(authUserArr));
      }

      localStorage.setItem(`${USER_VINA}_${userId}`, JSON.stringify(res.data));
      Cookies.set(`${ACCESS_TOKEN_KEY}_${userId}`, accessToken, {
        expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000),
      });
      Cookies.set(`${REFRESH_TOKEN_KEY}_${userId}`, refreshToken, {
        expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000),
      });
      Cookies.set(`act_${userId}`, accessToken, {
        expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000),
      });
      Cookies.set(`ref_${userId}`, refreshToken, {
        expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000),
      });
    }
    return res;
  }

  async loginFromStorage() {
    const client = ClientFactory.get();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get('u');
    if (queryString) {
      const accessToken = urlParams.get('ac'); //get accessToken
      const refreshToken = urlParams.get('rf'); //get refreshToken
      const odaToken = urlParams.get('ot'); // get odaToken
      const isLoginFromApp = urlParams.get('isLoginFromApp');
      const isApp = urlParams.get('isApp');
      const openFromApp = urlParams.get('openFromApp');
      const appVersion = urlParams.get('version');
      if (appVersion) {
        localStorage.setItem('appVersion', appVersion);
      }
      const clientId = urlParams.get('clientId');
      const redirectUrl = urlParams.get('redirectUrl');
      if (accessToken && refreshToken) {
        const password = urlParams.get('se') || process.env.REACT_APP_OPEN_CLOUD_PASS;
        const res = await this.handleSignIn(
          accessToken,
          refreshToken,
          odaToken,
          isLoginFromApp,
          userId,
          openFromApp
        );
        const { isExistOdaToken } = this.getToken();
        if (res && odaToken !== isExistOdaToken) {
          await this.loadConfig()
            .then(() => client.signInWithEmail(res.data.email, password))
            .then((user) => {
              this.setUser(user, true, res.data.id);
            })
            .then(() => {
              if (odaToken && isLoginFromApp === 'true') {
                window.open(
                  `vinacad://ac=${accessToken}&rf=${refreshToken}&ot=${odaToken}&u=${userId}`,
                  '_self'
                );
              }
            })
            .then(() => {
              window.location.replace(
                `${window.location.pathname}${userId.length ? `?u=${userId}` : ''}${
                  redirectUrl?.length ? `&redirectUrl=${redirectUrl}` : ''
                }${clientId?.length ? `&clientId=${clientId}` : ''}`
              );
            })
            .catch((err) => console.log('err', err));
        } else {
          window.location.replace(
            `${window.location.pathname}${userId.length ? `?u=${userId}` : ''}${
              redirectUrl?.length ? `&redirectUrl=${redirectUrl}` : ''
            }${clientId?.length ? `&clientId=${clientId}` : ''}`
          );
        }
      }
      if (isApp === 'true') {
        const { isExistAccessToken, isExistRefreshToken, isExistOdaToken } = this.getToken();
        this.handleOpenApp(isExistAccessToken, isExistRefreshToken, isExistOdaToken, userId);
      }
    }
    await this.loadConfig();
    const data = UserStorage.getItem(`user_${this.user_vina_id}`);
    if (!data) throw new Error('No user token found in the storage');
    const user = await client.signInWithToken(data.tokenInfo.token);
    this.setUser(user, true, this.user_vina_id);

    //login by VinaCAD
    if (urlParams.get('redirectUrl') && urlParams.get('clientId')) {
      const rf = Cookies.get(`${REFRESH_TOKEN_KEY}_${this.user_vina_id}`);
      const url = `${urlParams.get('redirectUrl')}?code=${rf}`;
      window.location.replace(url);
    }

    return user;
  }

  getToken() {
    const isExistAccessToken = Cookies.get(`${ACCESS_TOKEN_KEY}_${this.user_vina_id}`);
    const isExistRefreshToken = Cookies.get(`${REFRESH_TOKEN_KEY}_${this.user_vina_id}`);
    const userOda = JSON.parse(localStorage.getItem(`${USER_ODA}_${this.user_vina_id}`));
    return {
      isExistAccessToken,
      isExistRefreshToken,
      isExistOdaToken: userOda?.tokenInfo?.token,
    };
  }

  handleOpenApp(accessToken, refreshToken, odaToken, userId) {
    window.open(
      `vinacad://ac=${accessToken}&rf=${refreshToken}&ot=${odaToken}&u=${userId}`,
      '_self'
    );
    window.location.replace(window.location.pathname);
  }

  handleClearCaches(idUser) {
    Cookies.remove(`${ACCESS_TOKEN_KEY}_${idUser}`);
    Cookies.remove(`${REFRESH_TOKEN_KEY}_${idUser}`);
    localStorage.removeItem(`${USER_VINA}_${idUser}`);
    localStorage.removeItem(`${USER_ODA}_${idUser}`);
  }

  handleRemoveUser() {
    if (this.state.authUser.length > 1) {
      this.state.authUser.map((item) => {
        this.handleClearCaches(item.id);
      });
    } else {
      this.handleClearCaches(this.user_vina_id);
    }
    localStorage.removeItem(AUTH_USERS);
  }

  async logout(isLogoutMulti = false) {
    try {
      const body = this.state.authUser.map((item) => {
        const accessTokenCookie = Cookies.get(`${ACCESS_TOKEN_KEY}_${item.id}`);

        return {
          accessToken: accessTokenCookie,
        };
      });

      const res = await (isLogoutMulti
        ? axiosInstance.post('/auth/logout-multiple', body)
        : axiosInstance.post('/auth/logout', {
            currentToken: Cookies.get(`${ACCESS_TOKEN_KEY}_${this.user_vina_id}`),
          }));

      if (res) {
        this.handleRemoveUser();
        this.setUser();
        return res;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  setUser(user, rememberMe, user_vina_id) {
    if (user) {
      if (user.data) user.data.rememberMe = !!rememberMe;
      ClientFactory.get().options.data = user.customFields ?? {};
    }

    UserStorage.setItem(user?.data, `user_${user_vina_id}`);
    this.setState({ user });
  }

  setProject(project) {
    this.setState({ project });
  }

  async saveUserOptions(optionsData) {
    const data = {
      ...this.user.data,
      customFields: { ...this.user.data.customfields, ...optionsData },
    };
    const user = await this.user.update(data);
    this.setState({ user });
  }

  render() {
    return <AppContext.Provider value={{ app: this }}>{this.props.children}</AppContext.Provider>;
  }
}
