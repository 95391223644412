import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Button, Avatar, Dropdown } from 'antd';
import { UserOutlined, PlusOutlined, LogoutOutlined } from '@ant-design/icons';

import '../../components/PortalHeader.css';
import getUserIdLogin from '../../utils/getUserIdLogin';
import {
  ACCESS_TOKEN_KEY,
  AUTH_USERS,
  REFRESH_TOKEN_KEY,
  USER_ODA,
  USER_VINA,
  accountColorList,
} from '../../constants';
import { AppContext } from '../../AppContext';
import logo from '../../assets/images/logo-vinacad.png';
import logoBim from '../../assets/images/logo-vinabim.png';

const LayoutHeader = () => {
  const { app } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get('u');
  const user_vina_id = getUserIdLogin();
  const rf = Cookies.get(`${REFRESH_TOKEN_KEY}_${user_vina_id}`);
  const ac = Cookies.get(`${ACCESS_TOKEN_KEY}_${user_vina_id}`);
  const odaToken = JSON.parse(localStorage.getItem(`${USER_ODA}_${user_vina_id}`))?.tokenInfo.token;
  const authUser = JSON.parse(localStorage.getItem(AUTH_USERS)) || [];
  const [user, setUser] = useState({});

  const userObj = localStorage.getItem(`${USER_VINA}_${user_vina_id}`) || '{}';
  useEffect(() => {
    const parsedUser = JSON.parse(userObj);
    setUser(parsedUser);
  }, [userObj]);

  const getRedirectLink = (userIndex) => {
    return `/files?u=${userIndex !== undefined ? userIndex : ''}`;
  };

  const handleLogout = () => {
    app
      .logout(authUser.length > 1)
      .catch((e) => console.log('Cannot logout.', e.message))
      .finally(() => setLoading(false));
  };

  const items = [
    ...(authUser.length > 1
      ? authUser.map((item, index) => {
          return (
            item.email !== user.email && {
              key: `account_${index}`,
              label: (
                <a className="cursor-pointer" target="_blank" href={`${getRedirectLink(index)}`}>
                  {item.fullName || item.email}
                </a>
              ),
              icon: (
                <Avatar
                  style={{
                    backgroundColor: accountColorList[index],
                    verticalAlign: 'middle',
                    fontSize: '20px',
                  }}
                  size="middle"
                >
                  {item.email.slice(0, 1)}
                </Avatar>
              ),
            }
          );
        })
      : []),
    {
      key: 'addAccount',
      label: (
        <a
          className="cursor-pointer"
          target="_blank"
          href={`${process.env.REACT_APP_VINACAD_APP_URL}/login?addMore=true`}
        >
          {`  Thêm tài khoản ${authUser.length >= 1 ? 'khác' : ''} `}
        </a>
      ),
      icon: <PlusOutlined />,
    },
    {
      key: 'logout',
      label: (
        <a onClick={handleLogout} className="btn-logout cursor-pointer">
          {` Đăng xuất ${authUser.length > 1 ? 'tất cả tài khoản' : ''} `}
        </a>
      ),
      icon: <LogoutOutlined />,
    },
  ];

  const openVinaCAD = () => {
    // window.open(
    //   `${process.env.REACT_APP_VINACAD_APP_URL}${
    //     userId ? `?u=${userId}&fromPortal=true` : '?fromPortal=true'
    //   }`,
    //   '_blank'
    // );

    window.open(`vinacad://ac=${ac}&rf=${rf}&ot=${odaToken}&u=${userId}`, '_self');
  };

  const openVinaBIM = () => {
    window.open(`${process.env.REACT_APP_VINABIM}?code=${rf}`, '_blank');
  };

  return (
    <div className="layout-header">
      <div>
        <Button className="btn-redirect">
          <div className="d-flex align-items-center">
            <img style={{ width: '16px', height: '16px' }} src={logo} alt="Logo" />
            <p className="mb-0 ml-1">VinaCAD</p>
          </div>
        </Button>

        <Button className="btn-bim btn-redirect" onClick={openVinaBIM}>
          <div className="d-flex align-items-center">
            <img style={{ width: '16px', height: '16px' }} src={logoBim} alt="Logo" />
            <p className="mb-0 ml-1">VinaBIM</p>
          </div>
        </Button>

        {/* <Button className=" btn-redirect" disabled>
          VinaCAD Web
        </Button> */}
      </div>

      <Dropdown menu={{ items }} placement="bottomRight" overlayClassName="user-dropdown">
        <div className="info-user">
          <p className="user-name">{user.fullName || user.email}</p>
          <Avatar className="cursor-pointer" size={30} icon={<UserOutlined />} />
        </div>
      </Dropdown>
    </div>
  );
};

export default LayoutHeader;
